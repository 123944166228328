import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/css/question.less'
import '@/assets/css/main.less'
import '@/assets/css/theme.less'
import '@/assets/css/iconfont/iconfont.css'
import {components} from '@/utils/component.js'
import VXETable from 'vxe-table'
import vant from 'vant'
import localforage from 'localforage';
import VueUeditorWrap from 'vue-ueditor-wrap';

const app = createApp(App).use(VXETable)

localforage.config({
    driver: localforage.INDEXEDDB, // 选择存储引擎，如IndexedDB
    version: 1, // 数据库版本号
});


app.use(Antd)
app.use(vant)
app.use(router)
app.use(store)
app.use(components)
app.use(VueUeditorWrap)
app.mount('#app')
