<template>
    <a-menu
        v-model:openKeys="state.openKeys"
        v-model:selectedKeys="state.selectedKeys"
        class="menuStyle"
        mode="inline"
        ref="menu"
        v-if="state.menus && state.menus.length > 0"
    >
        <a-sub-menu :key="item.key" v-for="(item,index) in state.menus" >
            <template #icon>
               <span class="iconfont"></span>
            </template>
            <template #title>{{item.title}}</template>
            <a-menu-item :key="child.key" v-for="(child,childIndex) in item.children" @click="menuSelect(child,index)">{{child.title}}</a-menu-item>
        </a-sub-menu>
    </a-menu>
</template>
<script setup>
import {reactive, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter,useRoute} from 'vue-router'

const store = useStore()
const route = useRoute()
const router = useRouter()
const menu = ref(null)
const state = reactive({
    menus: [],
    selectedKeys: [],
    openKeys: []
})
if(store.getters.handleMenu.length > 0){
    state.menus = store.getters.handleMenu
    state.openKeys = [state.menus[0].key]
    state.selectedKeys = store.getters.menuSliderActive ? [store.getters.menuSliderActive] : state.menus[0].children.filter((item,index)=> index == 0).map(item=>{return item.key})
    
}

const menuSelect = (item,index)=>{
    store.commit('setMenuSliderActive', item.key)
    if(route.name != item.name)  router.push(item.path)
}

</script>