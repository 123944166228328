const system = [
    {
        name:'System',
        path:'/system',
        component: ()=>import('@/views/system/layout.vue'),
        meta:{
            title:'系统管理'
        },
        children:[
            {
                name:'Role',
                path:'role',
                component: ()=>import('@/views/system/role/index.vue'),
                meta:{
                    title:'角色管理'
                }
            },
            {
                name:'User',
                path:'user',
                component: ()=>import('@/views/system/user/index.vue'),
                meta:{
                    title:'用户管理'
                }
            },
            {
                name:'Dept',
                path:'dept',
                component: ()=>import('@/views/system/dept/index.vue'),
                meta:{
                    title:'组织机构'
                }
            },
        ]
    }
]

export default system